import React from "react"
import { Link } from "gatsby"
import Layout from "../Components/Layout"
import Box from "../Components/Box"
import RulesIlliustration from "../assets/images/rules-illiustration.jpg"
import LrtLogoDark from "../assets/svg/lrtl.svg"
import MmoLogoDark from "../assets/svg/Group 36.svg"
import TopIlliustration from "../assets/images/Kameros.png"
import LrtLogo from "../assets/svg/lrtl.svg"
import MmoLogo from "../assets/svg/Group 36.svg"

export default () => (
  <Layout>


    <div className="grid bg-blue" >


      <div className="grid--cell w-67 index--logo mm-height">
        <div className="index--logo-info">
          <div>Kviečia dalyvauti</div>
          <div className="index--logo-info-logos">
            <a
              href="https://lrt.lt"
              target="_blank"
              rel="noopener noreferrer"
              title="LRT"
            >
              <img height="40px" src={LrtLogoDark} alt="LRT" />
            </a>
            <a
              href="https://www.llri.lt/"
              target="_blank"
              rel="noopener noreferrer"
              title="LLRI"
            >
              <img height="40px" src={MmoLogoDark} alt="MMO" />
            </a>
          </div>
        </div>


        <div className="img-d">
          <img src={TopIlliustration} alt="" style={{maxWidth : "100%"}}/>
        </div>


      </div>

      <div className="grid--cell w-33 ">
        <div className="index--countdown">
          <div className="index--countdown-info">
            <div>Kviečia dalyvauti</div>
            <div className="index--countdown-info-logos">
              <a
                href="https://lrt.lt"
                target="_blank"
                rel="noopener noreferrer"
                title="LRT"
              >
                <img height="40px" src={LrtLogo} alt="LRT" />
              </a>
              <a
                href="https://www.llri.lt/"
                target="_blank"
                rel="noopener noreferrer"
                title="LLRI"
              >
                <img height="40px" src={MmoLogo} alt="MMO" />
              </a>
            </div>
          </div>



          <div className="index--countdown-completed">
            <h1 className="ended">
              TAISYKLĖS
            </h1>
          </div>




        </div>
      </div>


    </div>


    <div className="rules-page">

      <Box>
        <h1>Taisyklės</h1>

        <div className="text-container">


          <div className="red">EKONOMIKOS VIKTORINA SPRENDŽIAMA INTERNETE</div>
          <p>
            https://ekonomikavisur.lrt.lt/ <br/>
            Ekonomikos viktorina yra nemokama.

          </p>



          <div className="red">DATA IR LAIKAS</div>
          <p>
            Nuo 2022 m. balandžio 19 d. (antradienis)
          </p>
          <div className="red">KAIP DALYVAUTI EKONOMIKOS VIKTORINOJE?</div>
          <p>
            Užsiregistruokite puslapyje https://ekonomikavisur.lrt.lt/registration <br/>
            Registruotis galima iš anksto arba prieš pradedant spręsti ekonomikos viktoriną. <br/>
            Užsiregistravus gautise nuorodą, kurią patvirtinę savo el.pašte galėsite pradėti spręsti viktoriną.
          </p>
          <div className="red">DALYVIAI</div>
          <p>
            Ekonomikos viktorinoje gali dalyvauti visi norintys.
          </p>
          <div className="red">VIKTORINOS UŽDUOTYS IR EIGA</div>
          <p>
            Ekonomikos viktoriną sudaro 30 uždarų klausimų. <br/>
            Klausimai yra aktyvūs nuo balandžio 19 d. <br/>
            Kiekvienas dalyvis viktoriną gali spręsti daugiau nei vieną kartą. <br/>
            Ekonomikos viktorinos klausimai parengti Lietuvos laisvosios rinkos instituto ir viktorinos rėmėjų, remiantis Nacionalinio ekonomikos egzamino medžiaga.

          </p>



          <div className="red">VERTINIMAS</div>
          <p>
            Už kiekvieną teisingą uždaro tipo klausimą gaunamas 1 taškas.
          </p>
          <p>
            Klausimams atsakyti yra skiriamos 30 minučių. <br/>
            Užbaigus viktoriną pateikiami teisingi atsakymai ir jų paaiškinimai.

          </p>
          <p>
            Projekto pradžioje, iki birželio 1d. skelbiame konkursą. Kiekvieną savaitę geriausiai viktoriną išsprendę dalyviai gali laimėti prizus.
             </p>
          <p>
            Prizus galima laimėti sprendžiant viktoriną pirmuosius du kartus.
          </p>
          <p>
            Renkant prizų laimėtojus, bus atsižvelgiama į rezultatą bei sprendimo laiką.
          </p>
          <p>
            Jei dalyviai surenka vienodą balų skaičių (sumuojant viktorinos rezultatus ir sprendimo laiką), organizatorius pasilieka teisę nugalėtoją išrinkti burtų keliu.
          </p>
          <p>
            Su laimėtojais susisieksime registruojantis nurodytais kontaktais.
          </p>




          <div className="red">PRIZAI</div>

          <p>
            Projekto pradžioje, iki birželio 1d. skelbiame konkursą.
            </p>

          <p>
            Kiekvieną savaitę geriausiai ir greičiausiai viktoriną išsprendę dalyviai bus apdovanoti prizais:
            <br/>
            <ul>
              <li>Metinėmis žurnalo „IQ“ prenumeratomis!</li>
              <li>„LRT“ įsteigtais „Apple Watch“ išmaniaisiais laikrodžiais!</li>
              <li>„Telia“ įsteigtomis „JBL Flip 5 Eco“ kolonėlėmis!</li>
             <li>Mokykloms atiteks vadovėlių „Ekonomika per 31 valandą“ komplektai!</li>
<li>Klasę, kurios moksleiviai ekonomikos viktoriną išspręs geriausiai, apdovanosime banko „Luminor“ įsteigtu prizu – „Digiklasė“ finansinio raštingumo kursu!</li>
<li>“Biok” įsteigtais prizais!</li>
              <li>Geriausiai išsprendusį moksleivį apdovanosime 300 eurų piniginiu prizu!</li>
              <li>Geriausiai išsprendusį pilietį apdovanosime 300 eurų piniginiu prizu!</li>
              <li>Geriausiai išsprendusį studentą apdovanosime 300 eurų piniginiu prizu!</li>
              <li>Aktyviausiam mokytojui, kuris pritrauks daugiausiai moksleivių, atiteks 400 eurų piniginis prizas!*</li>


            </ul>
          </p>

          <p>
            * Mokytojas turi būti užsiregistravęs kaip viktorinos ambasadorius. Tai padaryti galima parašius el. paštu {" "}
            <a href="mailto:ekonomikavisur@llri.lt" title="ekonomikavisur@llri.lt">
              ekonomikavisur@llri.lt
            </a>. Mokytojo klasėms registruojantis spręsti testą, pastaboje reikia nurodyti mokytojo vardą ir pavardę. Jei viktoriną mokytojas organizuoja skirtingose mokyklose, informuokite el. paštu –  {" "}
            <a href="mailto:ekonomikavisur@llri.lt" title="ekonomikavisur@llri.lt">
              ekonomikavisur@llri.lt
            </a>
          </p>



          <div className="red">PRIVATUMO POLITIKA</div>
          <p>
            <Link to="/privacy-policy">LRT ir LLRI privatumo politika, slapukų naudojimo tvarka ir taisyklės.</Link>
          </p>


          <div className="red">DAUGIAU INFORMACIJOS</div>
          <p>
            Ekonomikos viktorinos elektroniniame puslapyje ekonomikavisur.lrt.lt <br/>
            Socialinio tinklo „Facebook“ paskyroje {" "} <a href="https://www.facebook.com/laisvosios.rinkos.institutas" title="https://www.facebook.com/laisvosios.rinkos.institutas">
            „Lietuvos laisvosios rinkos institutas“
          </a>
            <br/>
            El. paštu {" "} <a href="mailto:ekonomikavisur@llri.lt" title="ekonomikavisur@llri.lt">
            ekonomikavisur@llri.lt
          </a>

          </p>


        </div>
      </Box>
    </div>
  </Layout>
)
